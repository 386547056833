/* src/App.css */
/* Add your CSS rules here */
.color-empty {
  fill: #ebedf0; /* No contributions - light gray */
}

/* Combined contributions (both tasks and solutions) */
.color-scale-both-1 {
  fill: #e0f3e0; /* Very light green */
}

.color-scale-both-2 {
  fill: #a3e3a3; /* Light green */
}

.color-scale-both-3 {
  fill: #66d266; /* Medium green */
}

.color-scale-both-4 {
  fill: #33b833; /* Dark green */
}

.color-scale-both-5 {
  fill: #008000; /* Very dark green */
}

/* Task color scales (blue) */
.color-scale-task-1 {
  fill: #e0f2ff; /* Very light blue */
}

.color-scale-task-2 {
  fill: #99d3ff; /* Light blue */
}

.color-scale-task-3 {
  fill: #4da6ff; /* Medium blue */
}

.color-scale-task-4 {
  fill: #0066cc; /* Dark blue */
}

.color-scale-task-5 {
  fill: #003d99; /* Very dark blue */
}

/* Solution color scales (yellow) */
.color-scale-solution-1 {
  fill: #fff9e0; /* Very light yellow */
}

.color-scale-solution-2 {
  fill: #ffec99; /* Light yellow */
}

.color-scale-solution-3 {
  fill: #ffdb4d; /* Medium yellow */
}

.color-scale-solution-4 {
  fill: #ffcc00; /* Dark yellow */
}

.color-scale-solution-5 {
  fill: #b38600; /* Very dark yellow */
}



.legend {
  display: flex;
  align-items: center;
}

.legend-item {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.legend-square {
  width: 15px; /* Size of the square */
  height: 15px; /* Size of the square */
  margin-right: 5px; /* Space between square and text */
}

.color-scale-both {
  background-color: #40c463; /* Medium green color for both */
}

.color-scale-task {
  background-color: #0080ff; /* Dark blue color for tasks */
}

.color-scale-solution {
  background-color: #ffc107; /* Dark yellow color for solutions */
}

