@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

body {
    font-family: 'Nunito', sans-serif;
}

Button.greenButton {
  font-weight: 600;
  color: #fff;
  background-color: #4eab0d;
  font-size: 16px;
}

Button.greenButton:hover {
  background-color: #3d8a0a; /* Optional: Change background on hover */
}

Button.greenButton:active,
Button.greenButton:focus  {
  background-color: #4eab0d; /* Optional: Change background on hover */
}

Button.linkButton {
  font-weight: 600;
  background-color: #fff;
  color: #263c98;
  font-size: 16px;
  border: none; /* Optional: If you want to remove borders */
}

Button.linkButton:hover {
  background-color: #fff; /* Optional: Change background on hover */
  text-decoration: none; /* Ensure underline is removed on hover */
}

.nonStyledLink {
  text-decoration: none;  /* Remove underline */
  color: inherit;         /* Inherit color from parent */
}

.nonStyledLink:hover {
  text-decoration: none;  /* Ensure underline is removed on hover */
  color: inherit;         /* Maintain the inherited color on hover */
}

.nonStyledLink:active {
  text-decoration: none;  /* Ensure underline is removed when the link is active (clicked) */
  color: inherit;         /* Maintain the inherited color when the link is active */
}

.nonStyledLink:visited {
  color: inherit;         /* Maintain the inherited color for visited links */
}

.full_width.container {
  padding: 0;
  margin: 0;
  width: 100%;
}

.body {
  min-height: 100vh;
  font-family: sans-serif;
  margin: 0;
  font-size: 16px;
}

.scroll_to_top {
  z-index: 100;
  position: fixed;
  width: 50px;
  height: 50px;
  right: 0;
  bottom: 0;
  color: #0000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll_to_bottom {
  z-index: 100;
  position: fixed;
  width: 50px;
  height: 50px;
  right: 0;
  top: 4em;
  color: #0000;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul {
  margin: 0;
}
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "- ";
  text-indent: -5px;
}

.ro-ai-trainer {
  z-index: 10; 
  position: fixed; 
  width: 100%;
  height: 50px;
  right: 0px;
  bottom: 0px;
  background-color: white;
}

.appChatbotContainer {
  border-radius: 5px;
  bottom: 70px;
  box-shadow: 5px 5px 13px rgba(91,81,81,.4);
  display: flex;
  justify-content: center;
  margin: 40px 0;
  position: fixed;
  right: 10px;
  z-index: 6;
}

.appChatbotButton {
  background-color: #417fc2;
  border: none;
  border-radius: 25px;
  bottom: 50px;
  height: 50px;
  position: fixed;
  right: 10px;
  width: 50px;
  z-index: 6;
}

.appChatbotButtonIcon {
  fill: #fff;
  height: 25px;
  width: 25px;
}

/* Container styling */
.signin-container {
  width: 300px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* General input styling */
.signin-container input[type="text"],
.signin-container input[type="email"],
.signin-container input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  transition: all 0.3s ease;
}

/* Remove border from the SelectPicker toggle */
.rs-picker-toggle {
  border: none !important;
  font-size: 16px;
}

.rs-picker-select-menu-item {
  font-size: 16px; /* Change this value to your desired font size */
}

/* Optional: Remove border when active */
.rs-picker-toggle.rs-picker-toggle-active {
  border: none !important;
}

/* Optional: Remove border when disabled */
.rs-picker-toggle.rs-picker-toggle-disabled {
  border: none !important;
}


.signin-select-container {
  width: 100%;
  padding: 12px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  transition: all 0.3s ease;
}

/* Focus effects */
.signin-container input[type="text"]:focus,
.signin-container input[type="password"]:focus {
  border-color: #007BFF;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
  outline: none;
}

/* Button styling */
.signin-container Button[type="submit"] {
  width: 100%;
  background-color: #417fc2;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

/* Button hover effect */
.signin-container input[type="submit"]:hover {
  background-color: #0056b3;
}

/* Label styling */
.signin-container label {
  font-size: 14px;
  margin-bottom: 6px;
  display: block;
  color: #333;
}

.center-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  overflow-y: auto;
}

table.candidate_tab {
  width: 100%;
  border-collapse: collapse;
}

td.candidate_tab_field_title {
  width: 100px; /* Fixed width for the field title */
  font-weight: bold; /* Optional: Make the field title bold */
  vertical-align: top; /* Aligns text at the top */
  padding-right: 10px; /* Space between the title and the value */
}

td.candidate_tab_field_value {
  word-wrap: break-word; /* Allows text to wrap within the container */
}

.more_less_toggle_button {
  background: none;
  border: none;
  color: #007BFF; /* A nice blue color that stands out on white */
  cursor: pointer;
  padding: 0;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  margin-left: 5px;
}

.more_less_toggle_button:hover {
  color: #0056b3; /* Darker blue for hover effect */
}

.verification-container {
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
}
.verification-container input {
  width: 30px;
  height: 50px;
  text-align: center;
  font-size: 32px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.google-icon {
  content: url(../assets/images/google_icon_web_light_sq_na.svg);
}
.microsoft-icon {
  content: url(../assets/images/microsoft_icon_ms-symbollockup_mssymbol_19.svg);
}
.google-sign-in-button-image {
  content: url(../assets/images/google_icon_web_light_sq_SI.svg);
}

.no-scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.product-pricing-border {
  margin: 10px;
  border-radius: 10px;
  border: 2px solid #007BFF;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.blue-divider {
  border-top: 2px solid  #007BFF;
  margin: 20px 0
}

/* Example usage: <div className="ribbon ribbon-top-right"><span>25% Off with<br/>Promo Code ZIPPLY25</span></div> */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: -10px;
  right: -10px;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #f0c14b; /* Yellow color */
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 5px 0;
  background-color: #fff820; /* Yellow color */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #333;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* Container to wrap the input and icon */
.password-container {
  position: relative; /* Ensures the icon is positioned relative to this container */
  display: flex; /* Aligns the input and icon nicely */
  align-items: center;
  width: 100%; /* Adjust as per the desired width */
}

/* The input field */
.password-container input {
  flex: 1; /* Take up all available width except for the icon */
  padding-right: 30px; /* Ensures space for the icon */
  width: 100%; /* Make the input fill the container */
}

/* The eye icon */
.password-toggle-icon {
  position: absolute;
  right: 10px; /* Always 10px from the right edge of the input */
  top: 50%;
  transform: translateY(-50%); /* Vertically center the icon */
  cursor: pointer;
  user-select: none; /* Prevent text selection on click */
}

/* Social share button styling */
.facebook-button {
  background-color: #3b5998 !important;
  color: white !important;
}

.twitter-button {
  background-color: #1da1f2 !important;
  color: white !important;
}

.reddit-button {
  background-color: #f21d1d !important;
  color: white !important;
}

.email-button {
  background-color: #464040 !important;
  color: white !important;
}

.copy-button {
  background-color: #4eab0d !important;
  color: white !important;
}

