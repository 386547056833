body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
}

.container {
    max-width: 900px;
    margin: 20px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

header {
    text-align: center;
    margin-bottom: 20px;
}

h1 {
    color: #333;
}

.reward-time {
    color: #666;
    font-size: 14px;
}

.task-description {
    margin-bottom: 30px;
}

.cta {
    text-align: center;
    margin-bottom: 20px;
}

.social-share {
    text-align: center;
    margin: 20px 0;
}

.qr-code {
    text-align: center;
    margin: 20px 0;
}

.qr-image {
    width: 60px;
    height: 60px;
}

.solve-button {
    background-color: #4eab0d !important;
    color: #fff !important;
    padding: 10px 20px !important;
    border: none !important;
    border-radius: 6px !important;
    cursor: pointer !important;
    font-size: 16px !important;
    transition: background-color 0.3s ease !important;
}

footer {
    text-align: center;
    font-size: 12px;
    color: #666;
    margin-top: 40px;
}

footer a {
    color: #28a745;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}
