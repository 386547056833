@import '~rsuite/dist/rsuite.min.css';

.body-panel {
  margin-top: 10px;
  background: #fff;
  border-radius: 10px 10px 10px 10px;
}

/* Workspace, header */

/* Page defaults */
.ro-page-default {
  background-color: #ffffff;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;  
}

/* Header */
.ro-header {
  z-index: 10; 
  position: fixed; 
  width: 100%;
  top: 0;
  background-color: #ffffff;
}

/* Body includes side navbar and workspace */
.ro-body {
  background-color: #ffffff;
  margin-top: 107px;
}

/* Bottom is currently undefined with no content */
.ro-bottom {
  background-color: #f2f2f4;
  padding: 0px;
  margin: 0px;
}

.centered-modal .rs-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-modal .rs-modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-recaptcha {
  display: flex;
  justify-content: center;
  align-items: center;
}